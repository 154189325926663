import { createRouter, createWebHashHistory } from "vue-router";

const router = createRouter({
	history: createWebHashHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: "/",
			name: "home",
			meta: { title: "Home" },
			redirect: "/calendar",
		},
		{
			path: "/calendar",
			name: "calendar",
			meta: { title: "Scheduling" },
			component: () => import("@/views/Scheduling/CalendarView.vue"),
		},
		{
			path: "/onboarding",
			name: "onboarding",
			meta: { title: "Onboarding" },
			component: () => import("@/views/Onboarding/OnboardingView.vue"),
		},
	],
});

router.afterEach((to) => {
	document.title = to.meta.title
		? to.meta.title + " | Seedgreen"
		: "Seedgreen";
});

export default router;
