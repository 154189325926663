/** 2 Minutes */
export const STALE_TIME_SHORT = 2 * 60 * 1000;
/** 5 Minutes */
export const STALE_TIME_MEDIUM = 5 * 60 * 1000;
/** 10 Minutes */
export const STALE_TIME_LONG = 10 * 60 * 1000;
export let GC_TIME_STANDARD = 5 * 60 * 1000;
export function configureGcTime(time) {
    GC_TIME_STANDARD = time;
}
