<script setup lang="ts">
	import AlertsList from "@/components/AlertsList.vue";
	import api from "@/lib/seedgreen-api";
	import { useAlerts } from "@/query/alerts";
	import { useVerifyToken } from "@/query/validate-token";
	import _ from "lodash";
	import * as Sentry from "@sentry/vue";
	import Badge from "primevue/badge";
	import Button from "primevue/button";
	import Dropdown from "primevue/dropdown";
	import Menubar from "primevue/menubar";
	import type { MenuItem } from "primevue/menuitem";
	import OverlayPanel from "primevue/overlaypanel";
	import { Permission } from "seedgreen-shared/models/Permission.js";
	import {
		useCustomer,
		useCustomers,
	} from "seedgreen-shared/query/customer.js";
	import { usePermissions, useUser } from "seedgreen-shared/query/user.js";
	import { computed, ref, watch } from "vue";

	const {
		VITE_AZURE_STORAGE_ACCOUNT_NAME,
		VITE_AZURE_STORAGE_CONTAINER_NAME,
	} = import.meta.env;
	const LEGACY_URL = import.meta.env.VITE_SEEDGREEN_API_BASE_URL;

	const { customer } = useCustomer();
	const { user } = useUser();
	const { customers, isLoading: customersLoading } = useCustomers(user);
	const { tokenValid } = useVerifyToken();
	const { permissions } = usePermissions();
	const { alerts } = useAlerts();

	tokenValid;

	const tooltip = (label: string) => ({
		value: label,
		showDelay: 800,
		hideDelay: 200,
	});

	const logoUrl = computed(() => {
		const logoFilename = customer.value?.logoFilename;
		const defaultLogo = "logo-white-alt.png";
		const filename = logoFilename || defaultLogo;
		return `https://${VITE_AZURE_STORAGE_ACCOUNT_NAME}/${VITE_AZURE_STORAGE_CONTAINER_NAME}/${filename}`;
	});

	async function changeCustomer(customerId: number) {
		await api.post("User/ChangeCustomer", undefined, {
			params: { customerId: customerId },
		});

		// Are we viewing a customer-specific page?
		if (/^\/PlantingRegion\/Detail\/\d+$/.test(window.location.pathname))
			window.location.replace("/PlantingRegion/Detail");
		else window.location.reload();
	}
	const mouseOverCustomerDropdown = ref(false);
	const customerDropdownOpen = ref(false);

	async function logout() {
		await api.post("User/Logoff");
		const returnExtention = "?ReturnUrl=" + window.location.pathname;
		window.location.href = LEGACY_URL + "/Account/Login" + returnExtention;
	}

	const alertsPanel = ref();

	const navItems = computed(
		() =>
			[
				{
					label: "Enterprise Map",
					url: LEGACY_URL + "/",
				},
				{
					label: "Plantings",
					url: LEGACY_URL + "/Harvest",
				},
				{
					label: "Harvest",
					url: LEGACY_URL + "/HarvestDashboard",
				},

				user.value &&
					[3, 8].includes(user.value.customerId!) && {
						label: "Harvest Overview",
						url: LEGACY_URL + "/HarvestOverview",
					},

				{
					label: "Logistics",
					url: LEGACY_URL + "/Logistics",
				},

				// Scheduling
				user.value?.canViewScheduling && {
					label: "Scheduling",
					route: "/calendar",
					items: [
						{
							label: "Calendar",
							route: "/calendar",
						},
						{
							label: "Contractors & Crews",
							url: LEGACY_URL + "/Contractor",
						},
					],
				},

				// Divider
				{
					class: "sm:mr-auto",
				},

				// Admin
				(user.value?.internalUser ||
					permissions.value?.some((p) =>
						[
							Permission.ManageUserAccounts,
							Permission.ManageInternalUserAccounts,
							Permission.ViewUserActivity,
							Permission.AssignPermissions,
						].includes(p),
					)) && {
					label: "Admin",
					items: [
						(user.value?.internalUser ||
							permissions.value?.includes(
								Permission.ViewUserActivity,
							)) && {
							label: "User Activity",
							url: LEGACY_URL + "/UserActivity",
						},

						(user.value?.internalUser ||
							permissions.value?.some((p) =>
								[
									Permission.ManageUserAccounts,
									Permission.ManageInternalUserAccounts,
									Permission.AssignPermissions,
								].includes(p),
							)) && {
							label: "User Management",
							url: LEGACY_URL + "/Admin",
						},

						{
							label: "Onboarding",
							route: "/onboarding",
						},
					].filter(Boolean) as MenuItem[],
				},

				// Logout
				{
					label: "Logout",
					command: logout,
				},
			].filter(Boolean) as MenuItem[],
	);

	watch(user, () => {
		// this sets up the user data for Sentry when the user ref changes
		Sentry.setContext("User Data", user.value ?? {});
		Sentry.setUser({
			id: user.value?.id,
			username: user.value?.name,
			email: user.value?.userName,
			customerId: user.value?.customerId,
		});
	});
</script>

<template>
	<Menubar
		:exact="true"
		:model="navItems"
		:pt="{
			root: {
				class: [
					'h-[61px] w-full',
					'relative',
					'flex',
					'items-center',
					'p-0',
					'bg-gradient-to-r from-[#36787d] to-[#173549]',
					'border-b-[1px] border-black',
				],
			},
			menu: ({ props }) => ({
				class: [
					// Flexbox
					'sm:flex',
					'items-center',
					'flex-wrap',
					'flex-col sm:flex-row',
					{
						// @ts-ignore this code is from primevue presets, may not be typed correctly
						hidden: !(props != null && props.mobileActive),
						// @ts-ignore
						flex: props == null ? void 0 : props.mobileActive,
					},
					// Position
					'absolute sm:relative',
					'top-full left-0',
					'sm:top-auto sm:left-auto',
					// Size
					'w-full',
					'sm:h-full',
					// Spacing
					'm-0',
					'py-1 sm:py-0 sm:p-0',
					'list-none',
					// Shape
					'shadow-md sm:shadow-none',
					'border-0',
					// Color
					{
						// @ts-ignore
						'bg-gradient-to-r from-[#36787d] to-[#173549]':
							props?.mobileActive,
					},
					// Misc
					'outline-none',
				],
			}),
			menuitem: {
				class: 'sm:relative sm:w-auto sm:h-full w-full static',
			},
			content: () => ({
				class: [
					//  Colors
					'text-gray-200',
					// Hover States
					'hover:backdrop-brightness-[0.8] hover:text-gray-200',
					// etc
					'sm:h-full font-normal text-[1.4rem]',
				],
			}),
			action: ({ context }) => ({
				class: [
					'sm:h-full',
					'relative',
					// Flexbox
					'flex',
					'items-center',
					// Spacing
					'py-3',
					'px-4',
					// Size
					{
						'pl-9 sm:pl-6 sm:py-5 sm:hover:pl-8 sm:transition-all sm:duration-200':
							context.level === 1,
						'pl-14 sm:pl-5': context.level === 2,
					},
					'leading-none',
					// Misc
					'select-none',
					'cursor-pointer',
					'no-underline ',
					'overflow-hidden',
				],
			}),
			submenu: ({ props }) => ({
				class: [
					// Size
					'w-full sm:w-max sm:min-w-48',
					// Spacing
					'py-0',
					'm-0',
					'list-none',
					// Shape
					'shadow-none sm:shadow-md',
					'border-0',
					'rounded-b-md',
					// Position
					'static sm:absolute',
					'z-10',
					// @ts-ignore
					{ 'sm:absolute sm:left-full sm:top-0': props.level > 1 },
					// Color
					'bg-gradient-to-r from-[#36787d] to-[#173549]',
				],
			}),
			button: {
				class: [
					'flex sm:hidden',
					'items-center justify-center',
					'w-16',
					'h-16',
					'rounded-full',
					'text-surface-100',
					'hover:text-surface-200 dark:hover:text-white/60',
					'hover:backdrop-brightness-[0.9]',
					'focus:outline-none focus:outline-offset-0',
					'focus:ring focus:ring-primary-400/50 dark:focus:ring-primary-300/50',
					'transition duration-200 ease-in-out',
					'cursor-pointer',
					'no-underline',
				],
			},
			menubuttonicon: {
				class: 'w-[1.8rem] h-[1.8rem]',
			},
		}"
		:pt-options="{ mergeProps: false }"
	>
		<template #start>
			<div class="relative ml-10 w-[150px]">
				<img
					:src="logoUrl"
					class="mx-auto h-[52px] object-contain p-0"
				/>

				<!-- Customer Switcher -->
				<div
					v-if="user?.internalUser"
					class="absolute left-0 top-0 flex h-full w-full items-center justify-center"
					@mouseenter="mouseOverCustomerDropdown = true"
					@mouseleave="mouseOverCustomerDropdown = false"
				>
					<Dropdown
						v-if="mouseOverCustomerDropdown || customerDropdownOpen"
						:options="_.sortBy(customers, 'name')"
						option-label="name"
						option-value="id"
						:model-value="user?.customerId"
						scroll-height="90vh"
						:loading="customersLoading"
						@update:modelValue="changeCustomer"
						@before-show="customerDropdownOpen = true"
						@hide="customerDropdownOpen = false"
					/>
				</div>
			</div>
		</template>

		<template #end>
			<div>
				<Button
					class="mx-8 h-14 w-16"
					@click="(event) => alertsPanel.toggle(event)"
					severity="secondary"
					aria-label="Alerts"
					v-tooltip.bottom="tooltip('Alerts')"
				>
					<span class="fas fa-bell relative">
						<Badge
							v-if="(alerts?.length ?? 0) > 0"
							:value="alerts?.length"
							severity="danger"
							class="absolute right-[-8px] top-[-8px]"
							style="
								font-size: 1rem;
								font-weight: normal;
								font-family: initial;
							"
						/>
					</span>
				</Button>
			</div>
		</template>

		<template #item="{ item, props, hasSubmenu }">
			<router-link
				v-if="item.route"
				v-slot="{ href, navigate, isActive }"
				:to="item.route"
				custom
			>
				<a
					:href="href"
					v-bind="props.action"
					@click="navigate"
					:class="
						isActive
							? 'text-slate-100 backdrop-brightness-[0.9]'
							: ''
					"
				>
					<span :class="item.icon" />
					<span class="mx-2" :class="{ 'mr-0': hasSubmenu }">{{
						item.label
					}}</span>
					<i
						v-if="hasSubmenu"
						class="fas fa-caret-down ml-2 mr-1 text-[1.1rem] brightness-90"
					/>
				</a>
			</router-link>
			<a
				v-else
				:href="item.url"
				:target="item.target"
				v-bind="props.action"
			>
				<span :class="item.icon" />
				<span class="mx-2" :class="{ 'mr-0': hasSubmenu }">{{
					item.label
				}}</span>
				<i
					v-if="hasSubmenu"
					class="fas fa-caret-down ml-2 mr-1 text-[1.1rem] brightness-90"
				/>
			</a>
		</template>
	</Menubar>

	<OverlayPanel ref="alertsPanel">
		<div class="flex max-h-[40vh] w-[50rem] overflow-clip px-1">
			<AlertsList />
		</div>
	</OverlayPanel>
</template>
